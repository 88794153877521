/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConfig } from '@ws1c/intelligence-common';

/**
 *
 * Interceptor to add custom header X_TRANSACTION_ID for HttpRequest
 *
 * @export
 * @class HttpSentryErrorTracingInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class HttpSentryErrorTracingInterceptor implements HttpInterceptor {
  /**
   * If Sentry is enabed then add custom header X_TRANSACTION_ID
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof HttpSentryErrorTracingInterceptor
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (AppConfig.SENTRY) {
      request = request.clone({
        headers: request.headers.set('X-TRANSACTION-ID', AppConfig.SENTRY_TRANSACTION_ID),
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (AppConfig.SENTRY) {
          AppConfig.SENTRY.captureMessage(error.message, {
            level: 'info',
          });
        }
        return throwError(() => error);
      }),
    );
  }
}
