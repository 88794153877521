/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ApiUrlInterceptor } from './api-url.interceptor';
import { CorsInterceptor } from './cors.interceptor';
import { GraphQLInterceptor } from './graphql.interceptor';
import { HttpAuthenticationInterceptor } from './http-authentication.interceptor';
import { HttpRetryOnStatusCodeInterceptor } from './http-retry-on-status-code.interceptor';
import { HttpSentryErrorTracingInterceptor } from './http-sentry-error-tracing.interceptor';
import { HttpXsrfInterceptor } from './http-xsrf.interceptor';
import { TimezoneInterceptor } from './timezone.interceptor';

/** Http interceptor providers */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ApiUrlInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: GraphQLInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpSentryErrorTracingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TimezoneInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CorsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpRetryOnStatusCodeInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpAuthenticationInterceptor, multi: true },
];
