/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { retry } from 'rxjs/operators';

import { AppConfig } from '@ws1c/intelligence-common';

export type ShouldRetryFn = ({ status }: { status: number }) => boolean;

/**
 * Handle retry request on the following status codes
 * Rate Limit Error 429
 * Bad Gateway 502
 * Service Unavailable 503
 * Gateway Timeout 504
 * @export
 * @class HttpRetryOnStatusCodeInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class HttpRetryOnStatusCodeInterceptor implements HttpInterceptor {
  public static readonly RATE_LIMIT_ERROR: number = 429;
  public static readonly BAD_GATEWAY_ERROR: number = 502;
  public static readonly SERVICE_UNAVAILABLE_ERROR: number = 503;
  public static readonly GATEWAY_TIMEOUT_ERROR: number = 504;
  public static readonly FORBIDDEN_ERROR: number = 403;

  public static readonly MAX_ATTEMPTS: number = 5;
  public static readonly SCALING_DURATION_MS: number = 500;

  public static readonly RETRY_ON_STATUS_CODES: number[] = [
    HttpRetryOnStatusCodeInterceptor.RATE_LIMIT_ERROR,
    HttpRetryOnStatusCodeInterceptor.BAD_GATEWAY_ERROR,
    HttpRetryOnStatusCodeInterceptor.SERVICE_UNAVAILABLE_ERROR,
    HttpRetryOnStatusCodeInterceptor.GATEWAY_TIMEOUT_ERROR,
  ];

  /**
   * Creates an instance of HttpRetryOnStatusCodeInterceptor.
   * @param {HttpXsrfTokenExtractor} tokenExtractor
   * @memberof HttpRetryOnStatusCodeInterceptor
   */
  constructor(private tokenExtractor: HttpXsrfTokenExtractor) {}

  /**
   * Retry the request when we hit {RETRY_ON_STATUS_CODES}
   * See {MAX_ATTEMPTS} and {SCALING_DURATION_MS}
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof HttpRetryOnStatusCodeInterceptor
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { shouldRetry } = this;
    return next.handle(request).pipe(
      retry({
        count: HttpRetryOnStatusCodeInterceptor.MAX_ATTEMPTS,
        delay: (error, i: number) => {
          // If response is a status code we don't wish to retry, throw error
          if (!shouldRetry(error) && !this.isXsrfTokenMismatch(error, request)) {
            return throwError(() => error);
          }
          // retry after 1s, 2s, etc...
          return timer(i * HttpRetryOnStatusCodeInterceptor.SCALING_DURATION_MS);
        },
      }),
    );
  }

  /**
   * shouldRetry
   *
   * @private
   * @type {ShouldRetryFn}
   * @memberof HttpRetryOnStatusCodeInterceptor
   */
  private shouldRetry: ShouldRetryFn = (error: HttpErrorResponse) => {
    return HttpRetryOnStatusCodeInterceptor.RETRY_ON_STATUS_CODES.includes(error.status);
  };

  /**
   * isXsrfTokenMismatch
   * @private
   * @param {HttpErrorResponse} error
   * @param {HttpRequest<any>} request
   * @returns {boolean}
   * @memberof HttpRetryOnStatusCodeInterceptor
   */
  private isXsrfTokenMismatch = (error: HttpErrorResponse, request: HttpRequest<any>): boolean => {
    return (
      HttpRetryOnStatusCodeInterceptor.FORBIDDEN_ERROR === error.status &&
      request.headers.get(AppConfig.XSRF_HEADER_NAME) !== this.tokenExtractor.getToken()
    );
  };
}
