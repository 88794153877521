/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebError, WebErrorMessage } from '@dpa/ui-common';
import { Observable, throwError } from 'rxjs';

import { AppConfig, CookiesService } from '@ws1c/intelligence-common';
// eslint-disable-next-line
import { environment } from '../../../../environments/environment';

/**
 * Interceptor to stop the request if API_URL is not present in cookies
 *
 * @export
 * @class ApiUrlInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
  private static readonly ASSESTS_ENDPOINT = '/assets';

  /**
   * Creates an instance of ApiUrlInterceptor.
   * @param {CookiesService} cookieService
   * @memberof ApiUrlInterceptor
   */
  constructor(private cookieService: CookiesService) {}

  /**
   * Redirect to access denied page when API_URL is not set in cookies
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof ApiUrlInterceptor
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      environment.production &&
      !ApiUrlInterceptor.isLocalhost() &&
      !this.cookieService.getCookie(AppConfig.API_URL) &&
      !request.url.includes(ApiUrlInterceptor.ASSESTS_ENDPOINT)
    ) {
      return throwError(() => new WebError([new WebErrorMessage('No API URL')]));
    }
    return next.handle(request);
  }

  /**
   * isLocalhost
   * @static
   * @returns {boolean}
   * @memberof ApiUrlInterceptor
   */
  public static isLocalhost(): boolean {
    return window.location.hostname === 'localhost';
  }
}
