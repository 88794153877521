/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig, CookiesService, Endpoint } from '@ws1c/intelligence-common';

/**
 * GraphQLInterceptor
 * @export
 * @class GraphQLInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class GraphQLInterceptor implements HttpInterceptor {
  /**
   * Creates an instance of GraphQLInterceptor.
   * @param {CookiesService} cookiesService
   * @memberof GraphQLInterceptor
   */
  constructor(private cookiesService: CookiesService) {}

  /**
   * intercept
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const API_URL = this.cookiesService.getCookie(AppConfig.API_URL);
    let request = req;
    if (req.url === Endpoint.GRAPHQL && API_URL) {
      request = req.clone({
        url: `${API_URL}/${req.url}`,
      });
    }
    return next.handle(request);
  }
}
