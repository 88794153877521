/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';

/**
 * Interceptor to add custom header X-CLIENT-TZOFFSET for HttpRequest
 *
 * @export
 * @class TimezoneInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class TimezoneInterceptor implements HttpInterceptor {
  /**
   * intercept
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: request.headers.set('X-CLIENT-TZOFFSET', moment().format('Z')),
    });
    return next.handle(request);
  }
}
