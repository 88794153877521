/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

export const environment = {
  production: true,
  // user idle in second
  idle: 1800,
  timeout: 300,
};
