/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Interceptor to enable CORS for HttpRequest
 *
 * @export
 * @class CorsInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class CorsInterceptor implements HttpInterceptor {
  /**
   * intercept
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberOf CorsInterceptor
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true, // this is all the magic we need for now to allow cookies to send along with AJAX request
    });
    return next.handle(request);
  }
}
