/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '@ws1c/intelligence-common';

/**
 *
 * `HttpInterceptor` which adds an XSRF token to eligible outgoing requests.
 * Override the default Angular HttpXsrfInterceptor since it doesn't work with absolute API Path
 * and ignore GET request
 *
 * @export
 * @class HttpXsrfInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {
  /**
   * Creates an instance of HttpXsrfInterceptor
   * @param {HttpXsrfTokenExtractor} tokenService
   * @memberOf HttpXsrfInterceptor
   */
  constructor(private tokenService: HttpXsrfTokenExtractor) {}

  /**
   * intercept
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenService.getToken();
    // Be careful not to overwrite an existing header of the same name.
    if (token !== null && !req.headers.has(AppConfig.XSRF_HEADER_NAME)) {
      req = req.clone({
        headers: req.headers.set(AppConfig.XSRF_HEADER_NAME, token),
      });
    }
    return next.handle(req);
  }
}
